// libraries
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// images
import logo from 'assets/images/logotype-footer.png';
// components
import SocialContacts from 'components/shared/SocialContacts';

const Footer = () => (
  <StyledFooter>
    <div className="content">
      <StyledLink className="logotype" to="/">
        <img alt="" src={logo} />
        Custom
        {' '}
        <br />
        {' '}
        Masters
      </StyledLink>

      <div className="contacts">
        <div>
          Техподдержка:
        </div>
        <a className="phone" href="tel:+79533542968">
          +7 953 354 29 68
        </a>

        <div className="social-contacts-wrapper">
          <SocialContacts />
        </div>
      </div>
    </div>
  </StyledFooter>
);

export default Footer;

const StyledLink = styled(Link)`
    img {
      margin-right: 5px;
    }
    
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
`;

const StyledFooter = styled.footer`
  background-color: #fff;
  padding: 15px 0;
  margin-top: auto;

  .logotype {
    color: #DA0200;
    
    @media screen and (max-width: 767px) {
      img {
        display: none;
      }
    }
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
        
    @media screen and (max-width: 1440px) {
      border-top: 1px solid #DA0200;
      padding 15px;
    }
  }
  
  .menu-wrapper {
    display: flex;
    flex-grow: 1;
    margin-top: 7px;
    margin-left: 40px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 0 48px;
    
    li {
      padding: 0;
      font-weight: 600;
      
      + li {
        margin-top: 25px;
      }
    }
    
    a {
      color: #000;
      text-decoration: none;
    }
  }
  
  .contacts {
    flex-shrink: 0;
  }
  
  .phone {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #DA0200;
    text-decoration: none;
    display: inline-flex;
    margin-top: 4px;
  }
  
  .social-networks {
    margin: 7px 90px 0;

    .icon {
      fill: #DA0200;
      display: block;
      margin-bottom: 24px;
    }
  }
  
  .social-contacts-wrapper {
    margin-top: 17px;

    .icon {
      fill: #DA0200;
    }
  }
`;
