// libraries
import React from 'react';
import { Helmet } from 'react-helmet';
// components
import LandingLayout from 'layouts/Landing';
import Hero from 'components/landing/Hero';
import WhyUs from 'components/landing/WhyUs';
import Portfolio from 'components/landing/Portfolio';
import CreationSteps from 'components/landing/CreationSteps';
import CallToAction from 'components/landing/CallToAction';

const IndexPage = () => (
  <>
    <Helmet>
      <meta content="Создание сайтов, разработка сайтов, лендинг" name="keywords" />
      <link href="https://custom-masters.ru" rel="canonical" />
    </Helmet>
    <LandingLayout seoTitle="Лучшие сайты">
      <Hero />
      <WhyUs />
      <Portfolio />
      <CreationSteps />
      <CallToAction />
    </LandingLayout>
  </>
);

export default IndexPage;
