// libraries
import React from 'react';
import styled from 'styled-components';
// components
import Stripes from 'components/shared/Stripes';
import CallToActionForm from 'components/landing/CallToAction/Form';

const CallToAction = () => (
  <StyledWrapper>
    <div className="content">
      <StyledFormWrapper>
        <div className="stripes">
          <Stripes />
        </div>

        <div className="title">
          Приступим?
        </div>
        <CallToActionForm />
      </StyledFormWrapper>

      <StyledAdvantages>
        <div className="stripes">
          <Stripes />
        </div>

        <div className="subtitle">
          Продавайте в интернете просто и безопасно
        </div>

        <ul>
          <li>
            Интуитивно понятный интерфейс
          </li>
          <li>
            Гибкая настройка дизайна
          </li>
          <li>
            Поддержка на всех этапах
          </li>
          <li>
            Разнообразие платёжных систем
          </li>
          <li>
            Подробная пошаговая видеоинструкция
          </li>
          <li>
            SEO и раскрутка сайта
          </li>
        </ul>
      </StyledAdvantages>
    </div>

    <StyledBackgroundWrapper>
      <div className="form-bg" />
      <div className="advantages-bg" />
    </StyledBackgroundWrapper>
  </StyledWrapper>
);

export default CallToAction;

const StyledWrapper = styled.div`
  position: relative;

  .content {
    display: flex;
    position: relative;
    z-index: 1;
    
    @media screen and (max-width: 1440px) {
      padding: 0;
      flex-wrap: wrap;
    }
  }
  
  .stripes {
    position: absolute;
    top: 0;
    bottom: 0;
    
    > div {
      height: 100%;
    }
  }
`;

const StyledFormWrapper = styled.div`
  flex-basis: 50%;
  padding-top: 60px;
  padding-bottom: 30px;
  
  @media screen and (max-width: 1440px) {
      flex-basis: 100%;
      order: 1;
      padding: 15px;
  }
  
  .title {
    text-align: left;
    margin-bottom: 16px;
  }
  
  .stripes {
    left: -139px;
    
    @media screen and (max-width: 1440px) {
      display: none
    }
  }
`;

const StyledAdvantages = styled.div`
  background: #DA0200;
  color: #fff;
  flex-basis: 50%;
  position: relative;
  padding-top: 35px;
  right: -24px;
  
  @media screen and (max-width: 1440px) {
      flex-basis: 100%;
      order: 0;
      padding: 15px;
      right: auto;
  }
  
  .stripes {
    transform: rotate(180deg);
    right: -156px;
        
    @media screen and (max-width: 1440px) {
      display: none
    }

    span {
      background-color: #fff;
    }
  }
  
  .subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 16px;
  }
  
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
  }
  
  li {
    box-sizing: border-box;
    margin-bottom: 25px;
    position: relative;
    padding-left: 15px;
    
    &::before {
      content: '•';
      position: absolute;
      left: 0;
      display: block;
    }
    
    &:nth-child(2n + 1) {
      width: 58%;
    }

    &:nth-child(2n) {
      width: 42%;
    }
    
    @media screen and (max-width: 767px) {
      width: 100% !important;
  }
  }
`;

const StyledBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  pointer-events: none;
  z-index: -1;
  
    @media screen and (max-width: 1440px) {
      display: none;
  }
  
  .form-bg,
  .advantages-bg {
    width: 50%;
    position: relative;
  }
  
  .form-bg {
    &::after,
    &::before {
      content: '';
      background: linear-gradient(90deg, rgba(218,2,0,1) 0%, rgba(255,255,255,1) 50%, rgba(218,2,0,1) 100%);
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      height: 4px;
    }
    
    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .advantages-bg {
    background: #DA0200;
  }
`;
