// libraries
import React from 'react';
import styled from 'styled-components';
// components
import CreationStepsItem from 'components/landing/CreationSteps/Item';

const steps = [
  {
    id: 'email',
    title: 'Напишите нам или позвоните по телефонам',
    description: 'и мы пердоставим вам подробную консультацию ',
  },
  {
    id: 'template',
    title: 'Утверждаем дизайн и приступаем к разработке',
    description: 'Каждый этап работ будет согласовываться с вами',
  },
  {
    id: 'seo',
    title: 'Готово! Остаётся опубликовать сайт',
    description: 'Получение домена и настройка хостинга',
  },
];

const CreationSteps = () => (
  <CreationStepsSectionWrapper className="section">
    <div className="content">
      <div className="title">
        Создать — просто
      </div>

      <CreationStepsWrapper>
        {steps.map((item, index) => (
          <CreationStepsItem
            key={item.id}
            description={item.description}
            index={index + 1}
            title={item.title}
          />
        ))}
      </CreationStepsWrapper>
    </div>
  </CreationStepsSectionWrapper>
);

export default CreationSteps;

const CreationStepsSectionWrapper = styled.div`
  padding-top: 144px;

  @media screen and (max-width: 767px) {
    padding-top: 15px;
  }
  
  .title {
    margin-bottom: 15px;
    
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 1;
    }
  }
`;

const CreationStepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 81px;
 
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
`;
