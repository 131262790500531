// libraries
import React from 'react';
import styled from 'styled-components';
// icons
import IconStep from 'assets/icons/step.svg';

const CreationStepsItem = ({ title, description, index }) => (
  <StyledCreationStepsItemWrapper>
    <div className="step">
      <div className="number">
        {index}
      </div>
      <IconStep className="icon" />
    </div>

    <div className="name">
      {title}
    </div>

    <div className="text">
      {description}
    </div>
  </StyledCreationStepsItemWrapper>
);

export default CreationStepsItem;

const StyledCreationStepsItemWrapper = styled.div`
  flex-basis: 24%;
  text-align: center;
   
  @media screen and (max-width: 767px) {
    flex-basis: 100%;
  }
  
  &:not(:last-of-type) {
    .step {   
      &::after {
        background-color: #DA0200;
        content: '';
        height: 1px;
        position: absolute;
        width: 81%;
        left: 88%;
        top: 50%;
        
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  
  .icon {
    fill: #DA0200;
    margin: 33px 0 18px;
  }
  
  .name {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }
  
  .text {
    margin-top: 8px;
  }
  
  .step {
    position: relative;

    .number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-weight: 800;
      font-size: 48px;
      color: #fff;
    }
  }
`;
