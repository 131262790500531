// libraries
import React from 'react';
import styled from 'styled-components';

const WhyUsItem = ({ icon, title, description }) => {
  const Icon = icon;

  return (
    <StyledWhyUsItemWrapper>
      <div dangerouslySetInnerHTML={{ __html: title }} />
      <Icon className="icon" />
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </StyledWhyUsItemWrapper>
  );
};

export default WhyUsItem;

const StyledWhyUsItemWrapper = styled.div`
  flex-basis: 25%;
  text-align: center;
  
  @media screen and (max-width: 1023px) {
    flex-basis: 50%;
  }  
  
  @media screen and (max-width: 767px) {
    flex-basis: 100%;
  }  
  
  .icon {
    fill: #DA0200;
    margin: 33px 0 25px;
    width: 213px;
    height: 184px;
  }
`;
