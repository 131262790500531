// libraries
import React from 'react';
import styled from 'styled-components';
// icons
import IconDesign from 'assets/icons/disign.svg';
import IconTech from 'assets/icons/tech.svg';
import IconAnalytics from 'assets/icons/analytics.svg';
// components
import WhyUsItem from 'components/landing/WhyUs/Item';

const advantages = [
  {
    id: 'money',
    icon: IconDesign,
    title: 'Не можете представить<br />как будет выглядеть сайт?',
    description: 'Наш дизайнер поможет <br />выбрать лучшее решение',
  },
  {
    id: 'tech',
    icon: IconTech,
    title: 'Хочется просто вести бизнес и <br />не думать о технических моментах',
    description: 'Мы подерживаем вас на любом <br />этапе создания и существования сайта',
  },
  {
    id: 'analytics',
    icon: IconAnalytics,
    title: 'Важно знать сильные и слабые <br />места своего бизнеса',
    description: 'Мы проанализируем вашу идею <br />и предложим лучшие решения на выбор',
  },
];

const WhyUs = () => (
  <StyledSection className="section">
    <div className="content">
      <div className="title">
        Почему Custom Masters?
      </div>

      <StyledWhyUsWrapper>
        {advantages.map((item) => (
          <WhyUsItem
            key={item.id}
            description={item.description}
            icon={item.icon}
            title={item.title}
          />
        ))}
      </StyledWhyUsWrapper>
    </div>
  </StyledSection>
);

export default WhyUs;

const StyledSection = styled.div`
  @media screen and (max-width: 1023px) {
    padding-top: 25px;
  }   
  
  .title {
    @media screen and (max-width: 1023px) {
      color: #DA0200;
    }
    
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 15px;
    }
  }
    
  .icon {
    @media screen and (max-width: 767px) {
      width: 113px;
      height: 86px;
    }
  }
      
  .content {
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
`;

const StyledWhyUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 81px;
  
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }   
`;
