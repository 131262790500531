import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Gallery = ({ images, isMaximized, setGalleryVisible }) => (
  <StyledContainer>
    <Carousel images={images} isMaximized={isMaximized} shouldMinimizeOnSwipeDown={false} style={{ height: 500, width: 800 }} />
    <button
      className="close-button"
      onClick={() => setGalleryVisible(false)}
      type="button"
    />
  </StyledContainer>
);

export default Gallery;

const StyledContainer = styled.div`
    .close-button {
        position: fixed;
        top: 0;
        z-index: 9999;
        right: 0;
        font-weight: bold;
        font-size: 25px;
        width: 50px;
        height: 50px;
        background: transparent;
        cursor: pointer;
    }
`;
