// libraries
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  '+',
  '7',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const validation = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Имя должнобыть больше одного символа.')
    .required('Это обязательное поле.'),
  email: Yup.string()
    .email('Некорректный e-mail')
    .nullable()
    .notRequired(),
  phone: Yup.string()
    .required('Это обязательное поле.'),
  message: Yup.string()
    .max(1000, 'Максимум 1000 символов')
    .required('Это обязательное поле.'),
});

const CallToActionForm = ({ className }) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    setSuccessMessage('');
    setErrorMessage('');

    try {
      await fetch('https://api.telegram.org/bot5339309439:AAEy206ap2t5h7XLxqksHBs5MxJ-orfk5nU/sendMessage', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          chat_id: 5300356076,
          text: `
<b>Новая заявка на разработку:</b>
<b>Имя</b> ${values.name}
<b>Email</b> ${values.email}
<b>Телефон</b> ${values.phone}
<b>Сообщение</b> ${values.message}`,
          parse_mode: 'HTML',
        }),
      });

      resetForm();
      setSuccessMessage('Отлично, форма отправлена.');
    } catch (e) {
      setErrorMessage('Произошла досадная ошибка, пожалуйста свяжитесь с нами по телефону.');
    }
  };

  return (
    <StyledFormWrapper className={className || ''} id="form">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validation}
      >
        {(props) => {
          const {
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props;

          return (
            <Form noValidate>
              <label htmlFor="name" style={{ display: 'block' }}>
                Имя *
              </label>

              <Field
                id="name"
                name="name"
              />

              {errors.name && touched.name && (
              <div className="input-feedback">{errors.name}</div>
              )}

              <label htmlFor="email" style={{ display: 'block' }}>
                E-mail
              </label>

              <Field
                id="email"
                name="email"
              />

              {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
              )}

              <label htmlFor="phone" style={{ display: 'block' }}>
                Телефон *
              </label>

              <Field
                id="phone"
                name="phone"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    className={
                      errors.phone && touched.phone
                        ? 'text-input error'
                        : 'text-input'
                    }
                    id="phone"
                    mask={phoneNumberMask}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="+7 (XXX) XXX-XX-XX"
                    type="text"
                  />
                )}
              />

              {errors.phone && touched.phone && (
                <div className="input-feedback">{errors.phone}</div>
              )}

              <label htmlFor="message" style={{ display: 'block' }}>
                Сообщение
              </label>

              <Field
                as="textarea"
                id="message"
                name="message"
              />

              {errors.message && touched.message && (
              <div className="input-feedback">{errors.message}</div>
              )}

              <button disabled={isSubmitting} type="submit">
                Отправить
              </button>

              <div className="message-error">{errorMessage}</div>
              <div className="message-success">{successMessage}</div>

            </Form>
          );
        }}
      </Formik>
    </StyledFormWrapper>
  );
};

export default CallToActionForm;

const StyledFormWrapper = styled.div`
  input, textarea {
    max-width: 360px;
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    padding: 5px 0;
    margin: 10px 0;
  }
  
  button {
    display: block;
  }
  
  button {
    position: relative;
    background-color: #DA0200;
    border: none;
    font-size: 16px;
    color: #FFFFFF;
    padding: 10px;
    width: 200px;
    text-align: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
  }
  
  button:after {
    content: "";
    background: #d0c7c6;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
  }
  
  button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }
  
  .input-feedback {
    color: #DA0200;
    font-size: 12px;
  }
  
  .message-success {
    color: #0d910d;
  }
  
  .message-error {
    color: #DA0200;
  }
`;
