// libraries
import React from 'react';
import styled from 'styled-components';

const PortfolioItem = ({ image, title, setGalleryVisible }) => (
  <StyledPortfolioItem>
    <div className="image">
      <img alt={title} src={image} />
    </div>

    {title && (
      <div className="name" onClick={() => setGalleryVisible(true)}>
        {title}
      </div>
    )}
  </StyledPortfolioItem>
);

export default PortfolioItem;

const StyledPortfolioItem = styled.div`
  flex-basis: 24%;
  
  @media screen and (max-width: 1023px) {
    flex-basis: 49%;
  }
    
  @media screen and (max-width: 767px) {
    flex-basis: 100%;
  }
    
  .image {
    max-height: 400px;
    overflow: hidden;
  }
  
  img {
    display: block;
  }

  .name {
    cursor: pointer;
    margin: 10px 0;
    padding: 7px 5px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    background-color: #fff;
    text-align: center;
  }
`;
