// libraries
import React from 'react';
import styled from 'styled-components';
// images
import hero from 'assets/images/hero.png';
// components
import Stripes from 'components/shared/Stripes';
import HeroForm from 'components/landing/Hero/Form';

const Hero = () => (
  <>
    <StyledHeroSection>
      <div className="content">
        <div className="mobile-bg">
          <img alt="" src={hero} />
        </div>
        <div className="main">
          <div className="title">
            Создание сайтов под ключ
          </div>

          <div className="note">
            Мы предоставляем услуги по разработке сайтов &quot;под ключ&quot;,
            которые повышают ценность вашего бизнеса. Свяжитесь с нами сегодня
            и воплотите свои идеи в решения!
          </div>

          <HeroForm className="form-wrapper" />
        </div>

        <Stripes />

        <div className="hero">
          <img alt="" src={hero} />
        </div>
      </div>

      <StyledBackground>
        <div className="section-bg" />
        <div className="hero-bg" />
      </StyledBackground>
    </StyledHeroSection>
  </>
);

export default Hero;

const StyledHeroSection = styled.div`
  position: relative;

  .content {
    display: flex;
    position: relative;
    z-index: 1;
    
    @media screen and (max-width: 1023px) {
      max-width: 100%;
    }
    
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
    
    .mobile-bg {
      display: none;
      
      @media screen and (max-width: 1023px) {
        display: block;
        position: absolute;
        left:0;
        right: 0;
        top: 0;
        bottom: 0;
        
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          opacity: .1;
        }
      }
    }
  }
  
  .main {
    background-color: #DA0200;
    padding: 54px 0 0;  
    color: #fff;
    position: relative;
    margin-right: 23px;
    max-width: 678px;
    display: flex;
    flex-direction: column;
        
    @media screen and (max-width: 1023px) {
      max-width: 100%;
      width: 100%;
      background-color: transparent;
      color: #DA0200;
    }
            
    @media screen and (max-width: 767px) {
      margin: 0;
      padding: 15px;
    }
    
    &::after {
      content: '';
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      transform: matrix(0, -1, 1, 0, 0, 0);
      bottom: 0;
      position: absolute;
      display: block;
      left: -67%;
      top: 48%;
      width: 100%;
      z-index: 2;
      height: 4px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 48px;
    line-height: 65px;
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
    
    @media screen and (max-width: 1023px) {
      color: #DA0200;
    }
    
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 1;
    }
  }
  
  .note {
    font-size: 14px;
    line-height: 19px;
    max-width: 80%;
    
    @media screen and (max-width: 1023px) {
      color: #DA0200;
      line-height: 1;
      font-size: 32px;
    }
                
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  
  .form-wrapper {
    flex-grow: 1;
    margin-top: 54px;
    
    @media screen and (max-width: 1023px) {
      margin: 54px 0;
    }
  }
  
  .hero {
    margin: 6px -196px 0 30px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
`;

const StyledBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  
  .section-bg,
  .hero-bg {
    flex-grow: 1;
    max-width: 45%;
    position: relative;
  }
  
  .section-bg {
    background-color: #DA0200;
    
    @media screen and (max-width: 1023px) {
      background-color: #fff;
    }
    
    &::after {
      content: '';
      background: linear-gradient(270deg, #DA0200 63.68%, rgba(218, 2, 0, 0) 100%);
      transform: matrix(-1, 0, 0, -1, 0, 0);
      bottom: -4px;
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      height: 4px;
      z-index: 2;
    }
  }
  
  .hero-bg {
  &::after {
      content: '';
      background: linear-gradient(90deg, #DA0200 43.67%, rgba(218, 2, 0, 0) 100%);
      transform: matrix(-1, 0, 0, -1, 0, 0);
      bottom: -4px;
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      height: 4px;
      z-index: 2;
    }
  }
`;
