// libraries
import React from 'react';
import styled from 'styled-components';
// components
import Seo from 'components/shared/Seo';
import Header from 'components/shared/Header';
import Footer from 'components/shared/Footer';
// styles
import '../../index.scss';

const LandingLayout = ({ children, seoTitle }) => (
  <StyledPageWrapper>
    <Seo title={seoTitle} />

    <Header />

    <div className="page-content">
      {children}
    </div>

    <Footer />
  </StyledPageWrapper>
);

export default LandingLayout;

const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  .page-content {
    flex-grow: 1;
    overflow: hidden;
  }
`;
