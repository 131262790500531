// libraries
import React from 'react';
import styled from 'styled-components';

const Stripes = () => (
  <StyledStripes>
    <span />
    <span />
    <span />
    <span />
    <span />
  </StyledStripes>
);

export default Stripes;

const StyledStripes = styled.div`
  position: relative;
  width: 118px;
  
  @media screen and (max-width: 1023px) {
    display: none;
  }   
  
  span {
    background-color: #DA0200;
    position: absolute;
    top: 0;
    bottom: 0;
    
    &:nth-child(1) {
      left: 0;
      width: 24px;
    }
    
    &:nth-child(2) {
      left: 40px;
      width: 16px;
    }
    
    &:nth-child(3) {
      left: 72px;
      width: 8px;
    }
    
    &:nth-child(4) {
      left: 96px;
      width: 4px;
    }
    
    &:nth-child(5) {
      left: 116px;
      width: 2px;
    }
  }
`;
