// libraries
import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';

const HeroForm = ({ className }) => (
  <StyledFormWrapper className={className || ''}>
    <div className="field">
      <div className="text">
        Ваша идея, наша реализация
      </div>
    </div>

    <div className="action">
      <div className="button" onClick={() => scrollTo('#form')}>
        Подобрать сайт
      </div>
    </div>
  </StyledFormWrapper>
);

export default HeroForm;

const StyledFormWrapper = styled.div`
  display: flex;
  
  .field {
    max-width: 360px;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
                    
    @media screen and (max-width: 767px) {
      display: none;
    }
    
    &::before {
      background: linear-gradient(90deg, #fff 0.04%, rgba(255, 255, 255, 0) 100%);
      transform: matrix(-1, 0, 0, -1, 0, 0);
      content: '';
      height: 4px;
      width: 160%;
      left: 0;
      display: block;
      position: absolute;
      top: 0;
      
      @media screen and (max-width: 1023px) {
        width: 152%;
      }
      
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
  
  .action {
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-grow: 1;
    
    @media screen and (max-width: 1023px) {
        flex-grow: 0;
    }
    
    .button {
      border: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  
  .text {
    padding: 10px 15px;
    background-color: #ffffff;
    color: #DA0200;
  }
`;
