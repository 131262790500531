// libraries
import React from 'react';
import styled from 'styled-components';
// images
import portfolio1 from 'assets/images/А2.webp';
import portfolio2 from 'assets/images/portfolio-2.png';
import portfolio3 from 'assets/images/portfolio-3.png';
import portfolio4 from 'assets/images/portfolio-4.png';
// components
import PortfolioItem from 'components/landing/Portfolio/Item';
import Gallery from 'components/landing/Gallery';

const portfolio = [
  {
    id: 'portfolio1',
    image: portfolio1,
    title: 'Посмотреть',
  },
  {
    id: 'portfolio2',
    image: portfolio2,
    title: 'Посмотреть',
  },
  {
    id: 'portfolio3',
    image: portfolio3,
    title: 'Посмотреть',
  },
  {
    id: 'portfolio4',
    image: portfolio4,
    title: 'Посмотреть',
  },
];

const Portfolio = () => {
  const [isVisible, setGalleryVisible] = React.useState(false);

  const images = portfolio.map((item) => ({ src: item.image }));

  return (
    <>
      <StyledPortfolioWrapper className="section section-secondary">
        <div className="content">
          <div className="title">
            Наши работы
          </div>

          <div className="portfolio">
            {portfolio.map((item) => (
              <PortfolioItem
                key={item.id}
                image={item.image}
                setGalleryVisible={setGalleryVisible}
                title={item.title}
              />
            ))}
          </div>
        </div>
      </StyledPortfolioWrapper>

      {isVisible && <Gallery images={images} isMaximized setGalleryVisible={setGalleryVisible} />}
    </>
  );
};

export default Portfolio;

const StyledPortfolioWrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 63px;
  
  @media screen and (max-width: 1023px) {
    padding: 15px;
  }
  
  .content {
    @media screen and (max-width: 1023px) {
      max-width: 100%;
    }
    
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
  
  .title {
    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 1;
    }
  }
  
  .portfolio {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .actions {
    text-align: center;
    margin-top: 47px;
  }
  
  .button {
    font-weight: bold;
    display: inline-flex;
  }
`;
